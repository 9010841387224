const translations = {
  en: {
    title: "Museu da História do Videogame",
    description:
      "Preserving Brazil's unique gaming legacy and celebrating the evolution of video games. Opening Soon!",
    location: "São Caetano do Sul, SP, Brazil",
    opening: "Opening Spring 2025",
    waitlist: "Join the Waitlist",
    successMessage: "Form submitted successfully!",
    errorMessage: "Error sending form. Please try again.",
    support: "Support the Museum",
    name: "Name",
    email: "Email",
    newsletter: "Subscribe to our newsletter",
    phone: "Phone",
    companyName: "Company Name",
    supportDescription: "Tell us how you'd like to support the museum",
    submit: "Submit",
    individual: "Individual",
    company: "Company",
    aboutTitle: "About Us",
    aboutDescription:
      "The Video Game History Museum is a non-profit foundation dedicated to preserving history, studying and promoting video game culture in Brazil. More than just an exhibition space, the Museum will document the evolution of video games, with special attention to the unique chapter of this history in Brazil, while exploring the multiple disciplines involved in their development.",
    missionTitle: "Our Mission",
    missionDescription:
      "Preserve and democratize the history of video games in Brazil and worldwide, thus promoting digital inclusion and knowledge about their development, aligning it with the broader historical and social context.",
    objectivesTitle: "Our Objectives",
    objectives: [
      "Preserve and conserve significant video game history artifacts",
      "Offer educational programs exploring technology, art and culture",
      "Establish a center for academic video game studies",
      "Create partnerships with educational institutions and companies",
      "Elevate recognition of video games as artistic expression",
      "Create a meeting space for the gaming community",
      "Implement digital and social inclusion programs",
    ],
    donation: "Donation",
    volunteer: "Volunteer",
    partnership: "Partnership",
  },
  pt: {
    title: "Museu da História do Videogame",
    description:
      "Preservando o legado único dos jogos do Brasil e celebrando a evolução dos videogames. Inauguração em breve!",
    location: "São Caetano do Sul, SP, Brasil",
    opening: "Abertura no Outono de 2025",
    waitlist: "Entre na Lista de Espera",
    successMessage: "Formulário enviado com sucesso!",
    errorMessage: "Erro ao enviar formulário. Por favor, tente novamente.",
    support: "Apoie o Museu",
    name: "Nome",
    email: "E-mail",
    newsletter: "Inscreva-se em nossa newsletter",
    phone: "Telefone",
    companyName: "Nome da Empresa",
    supportDescription: "Diga-nos como você gostaria de apoiar o museu",
    submit: "Enviar",
    individual: "Pessoa Física",
    company: "Pessoa Jurídica",
    aboutTitle: "O que é",
    aboutDescription:
      "O Museu da História do Videogame é uma fundação sem fins lucrativos dedicada à preservação da história, o estudo e a promoção da cultura dos videogames no Brasil. Mais do que um espaço expositivo, o Museu documentará a evolução dos videogames, com especial atenção ao capítulo singular dessa história no Brasil, enquanto explora as múltiplas disciplinas envolvidas em seu desenvolvimento.",
    missionTitle: "Nossa Missão",
    missionDescription:
      "Preservar e democratizar a história dos videogames no Brasil e no mundo, promovendo assim a inclusão digital e o conhecimento sobre seu desenvolvimento, alinhando-a ao contexto histórico e social mais amplo.",
    objectivesTitle: "Nossos Objetivos",
    objectives: [
      "Preservar e conservar artefatos significativos da história dos videogames",
      "Oferecer programas educacionais que explorem tecnologia, arte e cultura",
      "Estabelecer um centro para estudos acadêmicos sobre videogames",
      "Criar parcerias com instituições educacionais e empresas",
      "Elevar o reconhecimento dos videogames como expressão artística",
      "Criar um espaço de encontro para a comunidade gamer",
      "Implementar programas de inclusão digital e social",
    ],
    donation: "Doação",
    volunteer: "Voluntariado",
    partnership: "Parceria",
  },
};

export default translations;

import emailjs from "@emailjs/browser";

class EmailService {
  constructor() {
    emailjs.init("PGHufvOh3IiLtqHi_");
  }

  async sendWaitlistNotification(userData) {
    try {
      const templateParams = {
        to_email: "contato@museudovideogame.org.br",
        from_name: userData.name,
        from_email: userData.email,
        data_inscricao: new Date().toLocaleString("pt-BR"),
        newsletter: userData.newsletter ? "Sim" : "Não",
        message: `Novo registro na lista de espera:
        Nome: ${userData.name}
        Email: ${userData.email}
        Newsletter: ${userData.newsletter ? "Sim" : "Não"}
        Data de Inscrição: ${new Date().toLocaleString("pt-BR")}`,
      };

      const response = await emailjs.send(
        "service_xhit868",
        "template_58xti04",
        templateParams
      );

      return response;
    } catch (error) {
      console.error("Erro ao enviar email da lista de espera:", error);
      throw error;
    }
  }

  async sendSupportNotification(supportData) {
    try {
      const templateParams = {
        to_email: "contato@museudovideogame.org.br",
        from_name: supportData.name,
        from_email: supportData.email,
        data_inscricao: new Date().toLocaleString("pt-BR"),
        type:
          supportData.type === "individual"
            ? "Pessoa Física"
            : "Pessoa Jurídica",
        contributionType: this.getContributionTypeLabel(
          supportData.contributionType
        ),
        phone: supportData.phone,
        description: supportData.description,
        message: `Nova solicitação de apoio:
        Tipo: ${
          supportData.type === "individual"
            ? "Pessoa Física"
            : "Pessoa Jurídica"
        }
        Forma de Contribuição: ${supportData.contributionType}
        Nome: ${supportData.name}
        ${
          supportData.type === "company"
            ? `Empresa: ${supportData.companyName}`
            : ""
        }
        Email: ${supportData.email}
        Telefone: ${supportData.phone}
        Descrição: ${supportData.description}
        Data de Inscrição: ${new Date().toLocaleString("pt-BR")}`,
      };

      const response = await emailjs.send(
        "service_xhit868",
        "template_qrj23ck",
        templateParams
      );

      return response;
    } catch (error) {
      console.error("Erro ao enviar email de apoio:", error);
      throw error;
    }
  }

  getContributionTypeLabel(type) {
    const types = {
      donation: "Doação",
      volunteer: "Voluntariado",
      partnership: "Parceria",
    };
    return types[type] || type;
  }
}

export default new EmailService();

<template>
  <main>
    <HeroSection />
  </main>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";

export default {
  name: "HomeView",
  components: {
    HeroSection,
  },
};
</script>

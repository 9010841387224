import { createStore } from "vuex";
import translations from "@/utils/translations";

export default createStore({
  state: {
    language: "pt",
    translations: translations,
  },
  getters: {
    getCurrentLanguage: (state) => state.language,
    getTranslations: (state) => state.translations[state.language],
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    changeLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
  },
});
